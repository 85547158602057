import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'
import Hero from 'components/Hero'

import 'styles/ContactPage.scss'
import GravityForm from '../components/GravityForm'

const Contact = ({ data }) => {
  const { wordpressPage: page, gfForm } = data
  const {
    acf: { hero, form },
  } = page

  const heroContent = (
    <>
      <Content className="hero-content" content={hero.content} />
      {hero.content_icons.map((item, key) => (
        <div key={key} className="hero-icons">
          <img
            className="mr-4"
            width="26px"
            height="26px"
            src={item.icon.localFile.publicURL}
            alt=""
          />
          {item.tekst}
        </div>
      ))}
    </>
  )

  return (
    <Layout>
      <SEO
        yoast={{ meta: page.yoast_meta }}
        lang={page.wpml_current_locale.slice(0, 2)}
      />
      <div className="contact-page">
        <Hero
          className="contact-hero"
          fluidImage={hero.image.localFile.childImageSharp.fluid}
          title={hero.title}
          description={heroContent}
        />

        <section className="form-section">
          <Image
            fluid={form.image.localFile.childImageSharp.fluid}
            className="form-image"
          />

          <div className="container">
            <div className="card form-card py-5 ">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <h2 className="with-dot text-center mb-3">{form.title}</h2>
                  <GravityForm
                    id={page.wpml_current_locale === 'nl_NL' ? 1 : 3}
                    perColumn={4}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactPageById($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      id
      wordpress_id
      title
      slug
      content
      wpml_current_locale
      yoast_meta {
        name
        content
        property
      }
      acf {
        hero {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          title
          content
          content_icons {
            icon {
              localFile {
                publicURL
              }
            }
            tekst
          }
        }

        form {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          title
        }
      }
    }
  }
`
